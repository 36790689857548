<template>
    <modal ref="detalleRelacion" :titulo="titulo">
        <div class="row mx-0 my-2 justify-center f-17 f-600 text-muted">
            Durante la última semana
        </div>
        <div class="overflow-auto custom-scroll" style="height:50vh;">
            <div v-for="(data, idx) in clientes" :key="idx" class="row mx-0 justify-center my-2">
                <div class="d-middle" style="width:200px;">
                    <img class="obj-cover br-5" width="50" height="50" :src="data.foto_firmada" alt="" />
                    <div class="col">
                        <p class="tres-puntos">{{ data.nombre }}</p>
                        <p class="f-14">{{ data.singular }}</p>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Metricas from "~/services/metricas"

export default {
    data(){
        return {
            ids:[],
            titulo:'',
            clientes:[]
        }
    },
    methods: {
        toggle(ids,titulo){
            this.$refs.detalleRelacion.toggle()
            this.ids = ids
            this.titulo = titulo
            this.info_tenderos()
        },
        async info_tenderos(){
            try {
                let params = {
                    ids: this.ids
                }
                const { data } = await Metricas.info_tenderos(params)
                this.$validar(data)
                this.clientes = data.clientes

            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>

<style lang="css" scoped>
</style>
